import React from "react";
import { Helmet } from "react-helmet-async";

const SEOMetaTags = ({
  title = "Md Mahbub Alam | Full Stack Developer & Software Engineer",
  description = "Md Mahbub Alam - Full Stack Developer at Brotecs Technologies Limited (Dhaka) and Stitel Networks, LLC (Arizona), specializing in React, Node.js, PHP, Laravel, and Cloud Solutions. 1.5+ years of experience building enterprise web applications. Based in Dhaka, Bangladesh.",
  keywords = "Md Mahbub Alam, Mahbub Alam, Full Stack Developer, Software Engineer, React Developer, Node.js Developer, PHP Developer, Laravel Expert, Bangladesh Software Engineer, Dhaka Developer, Enterprise Web Applications, Cloud Solutions",
  image = "/assets/img/profile.png", // Update with your actual OG image path
  url = "https://mahbub.dev", // Replace with your actual domain
}) => {
  return (
    <Helmet>
      {/* Basic Meta Tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta name="author" content="Md Mahbub Alam" />
      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:site_name" content="Mahbub Alam Portfolio" />
      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:url" content={url} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      <meta name="twitter:creator" content="@mahbubcse96" />{" "}
      {/* Add your Twitter handle */}
      {/* Additional SEO Tags */}
      <meta name="robots" content="index, follow, max-image-preview:large" />
      <meta name="language" content="English" />
      <meta name="geo.region" content="BD" />
      <meta name="geo.placename" content="Dhaka" />
      <link rel="canonical" href={url} />
      <link rel="alternate" hrefLang="en" href={url} />
      {/* Updated Structured Data / JSON-LD */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "Person",
          "@id": url,
          name: "Md Mahbub Alam",
          alternateName: ["Mahbub Alam", "Mahbub"],
          givenName: "Mahbub",
          familyName: "Alam",
          url: url,
          image: image,
          jobTitle: "Full Stack Developer",
          description:
            "Full Stack Developer with expertise in React, Node.js, PHP, Laravel, and Cloud Solutions. Currently working with Brotecs Technologies Limited (Dhaka) and Stitel Networks, LLC (Arizona).",
          worksFor: [
            {
              "@type": "Organization",
              name: "Brotecs Technologies Limited",
              url: "https://brotecs.com",
              jobTitle: "Full Stack Developer",
              address: {
                "@type": "PostalAddress",
                streetAddress:
                  "8th & 10th Floor, Suvastu Warda, House - 20 Road - Shahjalal Avenue",
                addressLocality: "Dhaka",
                postalCode: "1230",
                addressCountry: "Bangladesh",
              },
              contactPoint: {
                "@type": "ContactPoint",
                telephone: "+880-2-8933139",
                contactType: "business",
              },
              employmentType: "Full-time",
              workLocation: {
                "@type": "Place",
                name: "ABC skyrise tower",
              },
            },
            {
              "@type": "Organization",
              name: "Stitel Networks, LLC",
              url: "https://stitel.net",
              jobTitle: "Software Engineer",
              address: {
                "@type": "PostalAddress",
                streetAddress: "1330 W Auto Dr #107",
                addressLocality: "Tempe",
                addressRegion: "AZ",
                postalCode: "85284",
                addressCountry: "US",
              },
              contactPoint: {
                "@type": "ContactPoint",
                telephone: "+1-602-410-7728",
                contactType: "business",
              },
              employmentType: "Remote",
            },
          ],
          alumniOf: {
            "@type": "CollegeOrUniversity",
            name: "Stamford University Bangladesh",
            url: "https://stamforduniversity.edu.bd",
          },
          address: {
            "@type": "PostalAddress",
            addressLocality: "Dhaka",
            postalCode: "1230",
            addressRegion: "Dhaka",
            addressCountry: "Bangladesh",
          },
          sameAs: [
            "https://github.com/mahbub96",
            "https://linkedin.com/in/md-mahbub-alam-6b751821b",
            "https://fb.me/MahbubCSE96",
            "https://twitter.com/mahbubcse96",
          ],
        })}
      </script>
      {/* Updated Professional Profile Schema */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "ProfilePage",
          mainEntity: {
            "@type": "Person",
            name: "Md Mahbub Alam",
            description:
              "Full Stack Developer with experience in web applications and enterprise solutions. Currently working with Brotecs Technologies Limited (Dhaka, on-site) and Stitel Networks, LLC (Arizona, remote). Expertise in React.js, Node.js, PHP, Laravel, and cloud technologies.",
            hasOccupation: {
              "@type": "Occupation",
              name: "Full Stack Developer",
              skills: [
                "React.js",
                "Node.js",
                "PHP",
                "Laravel",
                "AWS",
                "Docker",
                "MongoDB",
                "MySQL",
                "REST API",
                "GraphQL",
                "TypeScript",
                "JavaScript",
                "VoIP Solutions",
                "Cloud Services",
              ],
              experienceRequirements: {
                "@type": "OccupationalExperienceRequirements",
                monthsOfExperience: 1.6,
              },
            },
            hasWorkHistory: [
              {
                "@type": "WorkPosition",
                name: "Full Stack Developer",
                worksFor: {
                  "@type": "Organization",
                  name: "Brotecs Technologies Limited",
                  address: {
                    "@type": "PostalAddress",
                    streetAddress:
                      "8th & 10th Floor, Suvastu Warda, House - 20 Road - Shahjalal Avenue",
                    addressLocality: "Dhaka",
                    postalCode: "1230",
                    addressCountry: "Bangladesh",
                  },
                },
                employmentType: "Full-time",
                workLocation: {
                  "@type": "Place",
                  name: "ABC skyrise tower",
                  address: {
                    "@type": "PostalAddress",
                    addressLocality: "Dhaka",
                    addressCountry: "Bangladesh",
                  },
                },
              },
              {
                "@type": "WorkPosition",
                name: "Software Engineer",
                worksFor: {
                  "@type": "Organization",
                  name: "Stitel Networks, LLC",
                  address: {
                    "@type": "PostalAddress",
                    streetAddress: "1330 W Auto Dr #107",
                    addressLocality: "Tempe",
                    addressRegion: "AZ",
                    postalCode: "85284",
                    addressCountry: "US",
                  },
                },
                employmentType: "Remote",
              },
            ],
            knowsAbout: [
              "Full Stack Development",
              "Enterprise Web Applications",
              "React.js",
              "Node.js",
              "PHP",
              "Laravel",
              "CodeIgniter",
              "Cloud Computing",
              "VoIP Solutions",
              "System Architecture",
              "Database Design",
              "API Development",
            ],
            workLocation: {
              "@type": "Place",
              address: {
                "@type": "PostalAddress",
                addressLocality: "Dhaka",
                addressCountry: "Bangladesh",
              },
            },
          },
        })}
      </script>
    </Helmet>
  );
};

export default SEOMetaTags;
